/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish';
import { Menu } from 'antd';
import { Link } from '@allenai/varnish-react-router';

import { About } from './pages/About';
import { Home } from './pages/Home';
import { AppRoute } from './AppRoute';
import headerLogo from './images/full-logo.svg';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
    {
        path: '/about',
        label: 'About',
        component: About,
    },
];

export const App = (props: RouteComponentProps) => {
    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <Header>
                        <Header.Columns columns="auto 1fr 170px">
                            <Header.Logo label={<Header.AppName></Header.AppName>}>
                                <Logo />
                            </Header.Logo>
                            <span />
                            <OverflowHidden>
                                <Header.MenuColumn>
                                    <Menu
                                        defaultSelectedKeys={[props.location.pathname]}
                                        mode="horizontal">
                                        {ROUTES.map(({ path, label }) => (
                                            <Menu.Item key={path}>
                                                <Link to={path}>{label}</Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </Header.MenuColumn>
                            </OverflowHidden>
                        </Header.Columns>
                    </Header>
                    <Content main>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </Content>
                    <Footer />
                </Layout>
            </Route>
        </BrowserRouter>
    );
};

const Logo = styled.img.attrs({
    src: headerLogo,
    alt: 'ACCoRD',
    height: '53px',
})`
    margin-right: ${({ theme }) => theme.spacing.sm};
`;

const OverflowHidden = styled.div`
    overflow: hidden;
`;

import React from 'react';

export const About = () => {
    return (
        <React.Fragment>
            <h1>About</h1>
            <p>
                A single scientific concept can be described in many different ways, benefiting
                differences in individuals’ background knowledge. However, current automatic concept
                description generation systems only produce a single “best” description in the
                context of a single document.
            </p>
            <p>
                To solve this problem, ACCoRD makes use of the variety of ways a concept is talked
                about across the scientific literature to produce multiple descriptions of a given
                target concept, in terms of distinct reference concepts. Our system automatically
                extracts the relevant text from the scientific literature and produces a succinct,
                self-contained summary of the concept description.
            </p>
            <p>
                To learn more, check out the{' '}
                <a href="https://github.com/allenai/ACCoRD" target="_blank" rel="noreferrer">
                    {' '}
                    dataset
                </a>
                .
            </p>
        </React.Fragment>
    );
};

export class Query {
    constructor(readonly concepta: string = '') {}

    private static readonly conceptaURLKey = 'a';

    isValid() {
        return this.concepta.length;
    }

    toQueryString(): string {
        const params = new URLSearchParams();
        params.set(Query.conceptaURLKey, this.concepta);
        return params.toString();
    }

    public static fromQueryString(searchParams: string): Query {
        const params = new URLSearchParams(searchParams);
        return new Query(params.get(Query.conceptaURLKey) ?? '');
    }
}

import axios from 'axios';

import { Query } from './Query';
import { Answer, Response } from './Answer';

export function getConceptBs(query: Query): Promise<Answer> {
    return axios.post<Response>('/api/get_conceptBs', query).then((resp) => {
        return {
            query: query,
            compare: resp.data.conceptA_rows.compare,
            contrast: resp.data.conceptA_rows.contrast,
            is_a: resp.data.conceptA_rows.is_a,
            part_of: resp.data.conceptA_rows.part_of,
            used_for: resp.data.conceptA_rows.used_for,
        };
    });
}

import axios from 'axios';

export interface Response {
    conceptAs: string[];
}

export interface Answer {
    query: string;
    suggestions: string[];
}

export function suggestConceptAs(query: string): Promise<Answer> {
    return axios.post<Response>('/api/suggest_conceptAs', { query: query }).then((resp) => {
        return {
            query: query,
            suggestions: resp.data.conceptAs,
        };
    });
}

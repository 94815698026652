import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { textStyles } from '@allenai/varnish';
import Highlighter from 'react-highlight-words';

import { Details } from '../api/Answer';

export const ExpandCard = ({
    concepta,
    conceptb,
    details,
}: {
    concepta: string;
    conceptb: string;
    details: Details[];
}) => {
    const [expand, setExpand] = useState(false);

    const getOverlapClasses = (overlaps: string[][]) => {
        return overlaps.reduce(function (map, obj) {
            const oval = obj.join(' ');
            if (oval !== concepta && oval !== conceptb) {
                map[oval] = 'overlap';
            }
            return map;
        }, {} as any);
    };
    return (
        <Card>
            <Description>
                <div>
                    {details.map((v, i) => {
                        const vOverlapClasses = getOverlapClasses(v.overlap);
                        return (
                            <React.Fragment key={i}>
                                <div>
                                    <textStyles.Jumbo>
                                        <HighlightText
                                            searchWords={[
                                                concepta,
                                                conceptb,
                                                ...Object.keys(vOverlapClasses),
                                            ]}
                                            textToHighlight={v.concept_description}
                                            highlightClassName={{
                                                [concepta]: 'concepta',
                                                [conceptb]: 'conceptb',
                                                ...vOverlapClasses,
                                            }}
                                        />
                                    </textStyles.Jumbo>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
                <ExpandButton onClick={() => setExpand(!expand)}>
                    {!expand ? <PlusOutlined /> : <MinusOutlined />}
                </ExpandButton>
            </Description>
            {expand ? (
                <div>
                    {details.map((v, i) => {
                        const vOverlapClasses = getOverlapClasses(v.overlap);
                        return (
                            <React.Fragment key={i}>
                                <DetailsTable>
                                    <Quote>
                                        <textStyles.Big>
                                            <q>
                                                <i>
                                                    <HighlightText
                                                        searchWords={[
                                                            concepta,
                                                            conceptb,
                                                            ...Object.keys(vOverlapClasses),
                                                        ]}
                                                        textToHighlight={v.source_text}
                                                        highlightClassName={{
                                                            [concepta]: 'concepta',
                                                            [conceptb]: 'conceptb',
                                                            ...vOverlapClasses,
                                                        }}
                                                    />
                                                </i>
                                            </q>{' '}
                                            —{' '}
                                            <a
                                                href={`https://api.semanticscholar.org/CorpusId:${v.paper_id}`}
                                                target="_blank"
                                                rel="noreferrer">
                                                Source Paper
                                            </a>
                                        </textStyles.Big>
                                    </Quote>
                                </DetailsTable>
                                {i < details.length - 1 ? <HR /> : null}
                            </React.Fragment>
                        );
                    })}
                </div>
            ) : null}
        </Card>
    );
};

const Card = styled.div`
    border-bottom: 1px solid ${({ theme }) => `${theme.color.N3.hex}`};
    padding: ${({ theme }) => `${theme.spacing.md}`};
    display: grid;
    gap: ${({ theme }) => `${theme.spacing.xs}`};
`;

const DetailsTable = styled.div`
    display: grid;
    gap: ${({ theme }) => `${theme.spacing.sm}`};
`;

const HR = styled.div`
    border-bottom: 1px solid ${({ theme }) => `${theme.color.N6.hex}`};
    padding-bottom: ${({ theme }) => `${theme.spacing.md}`};
    margin-bottom: ${({ theme }) => `${theme.spacing.md}`};
`;

const Description = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: ${({ theme }) => `${theme.spacing.xs}`};
    line-height: 1.5;
`;

const ExpandButton = styled(Button)`
    &&& {
        width: ${({ theme }) => `${theme.spacing.lg}`};
        height: ${({ theme }) => `${theme.spacing.lg}`};
        padding: 0;
        color: ${({ theme }) => `${theme.color.N9.hex}`};
        border: 1px solid ${({ theme }) => `${theme.color.N6.hex}`};
    }
`;

const HighlightText = styled(Highlighter)`
    mark {
        background-color: unset;
        font-weight: bold;
    }

    .concepta {
        background-color: unset;
        color: ${({ theme }) => `${theme.color.R8.hex}`};
    }
    .conceptb {
        background-color: unset;
        color: ${({ theme }) => `${theme.color.O9.hex}`};
    }
    .overlap {
        border-radius: 5px;
        padding: 0px 2px;
        font-weight: unset;
        background-color: ${({ theme }) => `${theme.color.N2.hex}`};
        color: ${({ theme }) => `${theme.color.N7.hex}`};
    }
`;

const Quote = styled.span`
    border-left: 3px solid ${({ theme }) => `${theme.color.A9.hex}`};
    padding-left: ${({ theme }) => `${theme.spacing.sm}`};
    padding-bottom: ${({ theme }) => `${theme.spacing.xs2}`};
    line-height: 1.5;
`;

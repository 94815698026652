import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Select, Alert, Form, List, Spin } from 'antd';
import { textStyles } from '@allenai/varnish';

import { suggestConceptAs } from '../api/suggestConceptAs';
import { getConceptBs } from '../api/getConceptBs';
import { Answer } from '../api/Answer';
import { Query } from '../api/Query';
import { ExpandCard } from '../components/ExpandCard';

type FormValue = Query;

export const Home = () => {
    const history = useHistory();
    const location = useLocation();
    const [form] = Form.useForm<FormValue>();
    const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
    const [answer, setAnswer] = useState<Answer | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [options, setOptions] = useState<{ value: string }[]>([]);

    useEffect(() => {
        fetchAnswer();
    }, [location]);

    const fetchAnswer = () => {
        const query = Query.fromQueryString(location.search);

        // ensure that we have the expected parameters in the URL
        if (query.isValid()) {
            const cleanedQuery = new Query(query.concepta.trim());
            form.setFieldsValue(cleanedQuery);

            // reset answers and errors before we go fetch new results
            setError(undefined);
            setAnswer(undefined);
            setIsFetchingAnswer(true);

            // Note: validation is occurring on the backend, so errors will be thrown if there are any invalid inputs
            getConceptBs(query)
                .then((responseAnswer) => {
                    // When the API returns successfully update the answer
                    setError(undefined);
                    setAnswer(responseAnswer);
                })
                .catch((err) => {
                    // See if we can display as specific of an error as possible
                    let error;
                    if (err.response) {
                        // If the check below is true, the API returned
                        // error message that's likely helpful to display
                        if (err.response.data && err.response.data.error) {
                            error = err.response.data.error;
                        } else if (err.response.status === 503) {
                            error =
                                'Our system is a little overloaded, ' +
                                'please try again in a moment';
                        }
                    }

                    // Fallback to a general error message
                    if (!error) {
                        error = 'Something went wrong. Please try again.';
                    }
                    setAnswer(undefined);
                    setError(error);
                })
                .finally(() => {
                    setIsFetchingAnswer(false);
                });
        }
    };

    const handleSubmit = (concepta: string) => {
        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        const query = new Query(concepta);
        // pushing this new URL will automatically trigger a new query (see the 'useEffect' function above)
        history.push(`/?${query.toQueryString()}`);
    };

    const onSearch = (searchText: string) => {
        suggestConceptAs(searchText).then((v) =>
            setOptions(
                v.suggestions.map((d) => {
                    return { value: d };
                })
            )
        );
    };

    const examples = ['beam search', 'bert', 'context vector', 'elmo'];
    return (
        <MaxTextWidth>
            <Title>Discover Diverse Descriptions of Scientific Concepts</Title>
            <p>
                <textStyles.Big>
                    Search our AI-curated corpus of diverse descriptions of concepts from the
                    computer science literature. ACCoRD is a free service of the non-profit{' '}
                    <a href="https://allenai.org" target="_blank" rel="noreferrer">
                        Allen Institute for AI
                    </a>
                    .
                </textStyles.Big>
            </p>
            <FormWrapper layout="vertical" form={form} scrollToFirstError>
                <h3>Search</h3>
                <Form.Item
                    name="concepta"
                    extra={
                        <span>
                            <strong>Try:</strong>{' '}
                            {examples.map((v, i) => (
                                <span key={v}>
                                    <a href={`/?a=${v}`}>{v}</a>
                                    {i < examples.length - 1 ? ', ' : '.'}
                                </span>
                            ))}
                        </span>
                    }
                    rules={[{ required: true }]}>
                    <Select
                        size="large"
                        showSearch={true}
                        showArrow={false}
                        placeholder="Enter a concept (e.g. 'bert')"
                        filterOption={false}
                        onSearch={onSearch}
                        onDropdownVisibleChange={(v) => {
                            if (v) {
                                onSearch('');
                            }
                        }}
                        onSelect={(value: string) => handleSubmit(value)}
                        disabled={isFetchingAnswer}>
                        {options.map((d) => (
                            <Select.Option key={d.value} value={d.value}>
                                {d.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </FormWrapper>
            <React.Fragment>
                {isFetchingAnswer ? (
                    <Loading>
                        <Spin size="large" tip="Loading…" />
                    </Loading>
                ) : null}
                {error && !answer ? (
                    <Alert type="error" message={error || 'Sorry, something went wrong.'} />
                ) : null}
                {!error && answer ? (
                    <React.Fragment>
                        {[
                            { data: (a: Answer) => a.compare, label: 'is like' },
                            { data: (a: Answer) => a.contrast, label: 'contrasts to' },
                            { data: (a: Answer) => a.is_a, label: 'is a' },
                            { data: (a: Answer) => a.part_of, label: 'is part of' },
                            { data: (a: Answer) => a.used_for, label: 'is used for' },
                        ].map((v) => {
                            return v.data(answer) ? (
                                <React.Fragment key={v.label}>
                                    <ConceptA>
                                        <Highlight>{answer.query.concepta}</Highlight> {v.label}:
                                    </ConceptA>
                                    <List
                                        pagination={{
                                            size: 'small',
                                            hideOnSinglePage: true,
                                            pageSize: 5,
                                        }}
                                        dataSource={Object.entries(v.data(answer) || {})}
                                        renderItem={(item) => (
                                            <ExpandCard
                                                key={item[0]}
                                                concepta={answer.query.concepta}
                                                conceptb={item[0]}
                                                details={item[1]}
                                            />
                                        )}
                                    />
                                </React.Fragment>
                            ) : null;
                        })}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        </MaxTextWidth>
    );
};

const Title = styled.h2`
    margin-top: 0;
`;

const FormWrapper = styled(Form)`
    max-width: 600px;
`;

const ConceptA = styled.h3`
    margin: 0;
    padding: 0;
`;

const Highlight = styled.span`
    color: ${({ theme }) => `${theme.color.R8.hex}`};
`;

const MaxTextWidth = styled.div`
    max-width: 900px;
`;

const Loading = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: ${({ theme }) => theme.spacing.xl};
`;
